import { lazy } from 'react';

const Settings = lazy(() => import('../pages/Settings'));
const Language = lazy(() => import('../pages/Language'));
const FAQ = lazy(() => import('../pages/FAQ'));
const FollowingFeed = lazy(() => import('../pages/FollowingFeed'));
const Risks = lazy(() => import('../pages/Risks'));
const Statistics = lazy(() => import('../pages/Statistics'));
const LoanIssuance = lazy(() => import('../pages/LoanIssuance'));
const LoanInsure = lazy(() => import('../pages/LoanInsure'));
const LoanExchange = lazy(() => import('../pages/LoanExchange'));
const Partners = lazy(() => import('../pages/Partners'));
const PartnersStatistics = lazy(() => import('../pages/PartnersStatistics'));
const Invest = lazy(() => import('../pages/Invest'));
const InvestPayment = lazy(() => import('../pages/InvestPayment'));
const Marketing = lazy(() => import('../pages/Marketing'));
const WebTokens = lazy(() => import('../pages/WebTokens'));
const Tasks = lazy(() => import('../pages/Tasks'));
const WithdrawalPage = lazy(() => import('../pages/Withdrawal'));
const Home = lazy(() => import('../pages/Home'));
const Premium = lazy(() => import('../pages/Premium'));
const BankGame = lazy(() => import('../pages/Game/BankGame'));
const PartnerDetails = lazy(() => import('../components/PartnerDetails'));
const SinglePost = lazy(() => import('../components/SinglePost'));

const routes = [
  { path: '/settings', component: Settings },
  { path: '/settings/language', component: Language },
  { path: '/settings/faq', component: FAQ },
  { path: '/settings/risks', component: Risks },
  { path: '/statistics', component: Statistics },
  { path: '/loan-issuance', component: LoanIssuance },
  { path: '/loan-insure', component: LoanInsure },
  { path: '/p2p-loans', component: LoanExchange },
  { path: '/partners', component: Partners },
  { path: '/partners/statistics', component: PartnersStatistics },
  { path: '/partners/:userId', component: PartnerDetails },
  { path: '/feed', component: FollowingFeed },
  { path: '/post/:postId', component: SinglePost },
  { path: '/invest', component: Invest },
  { path: '/invest/payment', component: InvestPayment },
  { path: '/invest/marketing', component: Marketing },
  { path: '/web-tokens', component: WebTokens },
  { path: '/web-tokens/tasks', component: Tasks },
  { path: '/withdraw', component: WithdrawalPage },
  { path: '/bank-game', component: BankGame },
  { path: '/premium', component: Premium },
  { path: '/', component: Home },
  { path: '*', component: Home },
];

export default routes;
