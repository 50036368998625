import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json'; // Английский
import zh from './locales/zh.json'; // Китайский
import hi from './locales/hi.json'; // Хинди
import es from './locales/es.json'; // Испанский
import fr from './locales/fr.json'; // Французский
import ar from './locales/ar.json'; // Арабский
import bn from './locales/bn.json'; // Бенгальский
import ru from './locales/ru.json'; // Русский
import pt from './locales/pt.json'; // Португальский
import id from './locales/id.json'; // Индонезийский
import it from './locales/it.json'; // Итальянский

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en }, // Английский
    zh: { translation: zh }, // Китайский
    hi: { translation: hi }, // Хинди
    es: { translation: es }, // Испанский
    fr: { translation: fr }, // Французский
    ar: { translation: ar }, // Арабский
    bn: { translation: bn }, // Бенгальский
    ru: { translation: ru }, // Русский
    pt: { translation: pt }, // Португальский
    id: { translation: id }, // Индонезийский
    it: { translation: it }, // Итальянский
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
